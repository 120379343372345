// Purchase Register
export const purchaseRegisterBaseUrl = 'dae/purchase-inventory/purchase-register/'
export const designationWiseUser = purchaseRegisterBaseUrl + 'get-user'
export const purchaseRegisterList = purchaseRegisterBaseUrl + 'list'
export const purchaseRegisterStore = purchaseRegisterBaseUrl + 'store'
export const purchaseRegisterUpdate = purchaseRegisterBaseUrl + 'update'
export const purchaseRegisterShow = purchaseRegisterBaseUrl + 'show'
export const purchaseRegisterForward = purchaseRegisterBaseUrl + 'forward'
export const purchaseRegisterStatus = purchaseRegisterBaseUrl + 'toggle-status'
export const purchaseRegisterDestroy = purchaseRegisterBaseUrl + 'destroy'
export const purchaseRequestList = purchaseRegisterBaseUrl + 'request-list'
export const purchaseRequestStatusUpdate = purchaseRegisterBaseUrl + 'status-update'
export const purchaseApproveList = purchaseRegisterBaseUrl + 'approve-list'

// Stock Register
export const stockRegisterBaseUrl = 'dae/purchase-inventory/stock-register/'
export const stockRegisterList = stockRegisterBaseUrl + 'list'
export const stockRegisterStore = stockRegisterBaseUrl + 'store'
export const stockRegisterUpdate = stockRegisterBaseUrl + 'update'
export const stockRegisterShow = stockRegisterBaseUrl + 'show'
export const stockRegisterStatus = stockRegisterBaseUrl + 'toggle-status'
export const stockRegisterDestroy = stockRegisterBaseUrl + 'destroy'
export const stockRegisterPurchaseRegisterList = stockRegisterBaseUrl + 'purchase-register-list'
