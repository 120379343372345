<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                            <b-col lg="12" sm="12">
                                <div>
                                <b-table-simple striped bordered small>
                                    <b-tr>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.purchase_serial_no') }}</b-th>
                                        <b-td>{{ $n(formData.purchase_serial_no, { useGrouping: false }) }}</b-td>
                                        <b-th>{{ $t('barc_config.fiscal_year') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.fiscal_year : formData.fiscal_year_bn }}</b-td>
                                        <b-th>{{ $t('budget.season') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.season_name : formData.season_name_bn }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('bsri_config.subsidy_type') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.subsidy_type : formData.subsidy_type_bn }}</b-td>
                                        <b-th>{{ $t('bsri_config.subsidy_name') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.subsidy_name : formData.subsidy_name_bn }}</b-td>
                                        <b-th>{{ $t('bsri_config.project_name') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.project_name : formData.project_name_bn }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('org_pro_division.division') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.division : formData.division_bn }}</b-td>
                                        <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.district : formData.district_bn }}</b-td>
                                        <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.upazilla : formData.upazilla_bn }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.purchase_order_date') }}</b-th>
                                        <b-td>{{ formData.purchase_order_date | dateFormat }}</b-td>
                                        <b-th>{{ $t('dae_config.s_name') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.supplier_name : formData.supplier_name_bn }}</b-td>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.supplier_mobile') }}</b-th>
                                        <b-td>{{ formData.supplier_mobile }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.supplier_address') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.supplier_address : formData.supplier_address_bn  }}</b-td>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.payment_mode') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.payment_mode_name : formData.payment_mode_name_bn }}</b-td>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.payment_status') }}</b-th>
                                        <b-td>{{ currentLocale === 'en' ? formData.payment_status_name : formData.payment_status_name_bn }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.supplier_invoice_date') }}</b-th>
                                        <b-td>{{ formData.supplier_invoice_date | dateFormat }}</b-td>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.supplier_invoice_attachment') }}</b-th>
                                        <b-td><a v-if="formData.supplier_invoice_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="incentiveGrantServiceBaseUrl + 'uploads/purchase-register/original/' + formData.supplier_invoice_attachment" download><i class="fas fa-download"></i> {{ $t('globalTrans.view_download') }}</a></b-td>
                                        <b-th>{{ $t('globalTrans.status') }}</b-th>
                                        <b-td>{{ formData.status === 1 ? $t('globalTrans.active') : $t('globalTrans.inactive') }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                                </div>
                                <div style="font-size:15px; background-color: #005B5B;">
                                <h5 class="text-white text-center">
                                    {{ $t('dae_grant_allocation_distribution.purchase_register') }} {{ $t('globalTrans.details') }} {{ $t('globalTrans.list') }}
                                </h5>
                                </div>
                                <b-table-simple striped bordered small class="mt-2">
                                <b-thead>
                                    <tr>
                                    <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                    <b-th>{{ $t('dae_config.crop_name') }}</b-th>
                                    <b-th>{{ $t('dae_config.agriculture_material_type') }}</b-th>
                                    <b-th>{{ $t('dae_config.agriculture_material_name') }}</b-th>
                                    <b-th class="text-right">{{ $t('globalTrans.quantity') }}</b-th>
                                    <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.price_per_unit') }}</b-th>
                                    <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.total_price') }}</b-th>
                                    </tr>
                                </b-thead>
                                <b-tbody>
                                    <template v-if="formData.purchase_register_details.length">
                                    <b-tr v-for="(item, index) in formData.purchase_register_details" :key="index">
                                        <b-td  class="text-center">{{ index+1 }}</b-td>
                                        <b-td class="text-center">{{ currentLocale === 'en' ? item.crop_name : item.crop_name_bn }}</b-td>
                                        <b-td class="text-center">{{ currentLocale === 'en' ? item.material_type_name : item.material_type_name_bn }}</b-td>
                                        <b-td class="text-center">{{ currentLocale === 'en' ? item.material_name : item.material_name_bn }}</b-td>
                                        <b-td class="text-right">{{ $n(item.quantity) }}</b-td>
                                        <b-td class="text-right">{{ $n(item.price_per_unit) }}</b-td>
                                        <b-td class="text-right">{{ $n(item.total_price) }}</b-td>
                                    </b-tr>
                                    </template>
                                    <template v-else>
                                    <b-tr>
                                        <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                    </b-tr>
                                    </template>
                                </b-tbody>
                                <b-tfoot>
                                    <b-tr>
                                    <b-th colspan="6" class="text-right">{{ $t('dae_grant_allocation_distribution.total_price') }}</b-th>
                                    <b-th class="text-right">{{ grandTotal }}</b-th>
                                    </b-tr>
                                </b-tfoot>
                                </b-table-simple>
                            </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <h4>{{ $t('budget.forwardComments') }}</h4>
                                    <b-table-simple striped bordered small class="mt-2">
                                        <b-tr>
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('budget.sendFrom') }}</b-th>
                                            <b-th>{{ $t('budget.sendTo') }}</b-th>
                                            <b-th>{{ $t('budget.comment') }}</b-th>
                                        </b-tr>
                                        <b-tr v-for="(forward,index) in formData.forward" :key="index">
                                            <b-td>{{ $n(index+1) }}</b-td>
                                            <b-td>{{ getUserName(forward.sender_id) }}</b-td>
                                            <b-td>{{ getUserName(forward.receiver_id) }}</b-td>
                                            <b-td>{{ forward.comment }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { authServiceBaseUrl, incentiveGrantServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.formData = this.item
    this.getForwardedUsers(this.formData.forward)
    const supplier = this.supplierList.find(item => item.value === this.formData.supplier_id)
    const paymentMode = this.paymentModeList.find(item => item.value === this.formData.payment_mode)
    const paymentStatus = this.paymentStatusList.find(item => item.value === this.formData.payment_status)
    this.formData.supplier_name = supplier?.text_en
    this.formData.supplier_name_bn = supplier?.text_bn
    this.formData.payment_mode_name = paymentMode?.text_en
    this.formData.payment_mode_name_bn = paymentMode?.text_bn
    this.formData.payment_status_name = paymentStatus?.text_en
    this.formData.payment_status_name_bn = paymentStatus?.text_bn
    this.formData.purchase_register_details = this.formData.purchase_register_details.map(item => {
      const crop = this.cropNameList.find(cropObj => cropObj.value === item.crop_id)
      const agMaterialType = this.agMaterialTypeList.find(agMaterialTypeObj => agMaterialTypeObj.value === item.material_type_id)
      const agMaterial = this.agMaterialList.find(agMaterialObj => agMaterialObj.value === item.material_id)
      const customItem = {}
      customItem.crop_name = crop?.text_en
      customItem.crop_name_bn = crop?.text_bn
      customItem.material_type_name = agMaterialType?.text_en
      customItem.material_type_name_bn = agMaterialType?.text_bn
      customItem.material_name = agMaterial?.text_en
      customItem.material_name_bn = agMaterial?.text_bn

      return Object.assign({}, item, customItem)
    })
  },
    data () {
        return {
            formData: {},
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            grandTotal: 0,
            users: []
        }
    },
    mounted () {
        core.index()
        this.getGrandTotal()
    },
    computed: {
        cropNameList: function () {
            return this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
        },
        agMaterialTypeList: function () {
            return this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
        },
        agMaterialList: function () {
            return this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1)
        },
        supplierList: function () {
            return this.$store.state.incentiveGrant.commonObj.supplierList.filter(item => item.status === 1)
        },
        currentLocale: function () {
            return this.$i18n.locale
        },
        paymentModeList: function () {
            return [
                { text: this.currentLocale === 'en' ? 'Cash' : 'নগদ', value: 1, text_en: 'Cash', text_bn: 'নগদ' },
                { text: this.currentLocale === 'en' ? 'Credit' : 'ক্রেডিট', value: 2, text_en: 'Credit', text_bn: 'ক্রেডিট' }
            ]
        },
        paymentStatusList: function () {
            return [
                { text: this.currentLocale === 'en' ? 'Paid' : 'পরিশোধ', value: 1, text_en: 'Paid', text_bn: 'পরিশোধ' },
                { text: this.currentLocale === 'en' ? 'Unpaid' : 'অবৈতনিক', value: 2, text_en: 'Unpaid', text_bn: 'অবৈতনিক' }
            ]
        }
    },
    methods: {
        async getForwardedUsers (forwards) {
            this.loading = true
            var userIds = []
            forwards.map((item, key) => {
                userIds.push(item.sender_id)
                userIds.push(item.receiver_id)
            })

            const params = Object.assign({}, this.search, { user_ids: userIds })
            await RestApi.getData(authServiceBaseUrl, '/user/user-list', params).then(response => {
                if (response.success) {
                    this.users = response.data
                } else {
                    this.users = []
                }
                this.loading = false
            })
        },
        getUserName (userId) {
            const user = this.users.find(user => user.value === userId)
            return user !== undefined ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
        },
        getGrandTotal () {
            let grandTotal = 0
                this.formData.purchase_register_details.filter(item => {
                    grandTotal += item.total_price
                })
            this.grandTotal = grandTotal
        }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
